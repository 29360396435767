@import './styles/variables.scss';
@import 'bulma-switch/dist/css/bulma-switch.min.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 1vh 1vw;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.table td,
.table th {
    vertical-align: baseline !important;
}

.table tbody td [class*='buttongroup'] {
    gap: var(--bespin-lib-measure-6);
}

header[class*='mainNav'] {
    width: 100% !important;
}

h1.title {
    text-align: left;
}

nav[class*='_navbar_'] {
    margin-bottom: var(--bespin-lib-measure-16);
}

nav[class*='_nav__'] {
    padding: var(--bespin-lib-measure-8) 0 0 var(--bespin-lib-measure-22);
}

nav a[class*='_navbarItem_']:hover {
    color: var(--bespin-lib-color-button-secondary-disabled-text);
}